@font-face {
  font-family: HelveticaRegular;
  font-weight: 300;
  font-style: normal;
  src: url(/fonts/helvetica_n4.fe093fe9ca22a15354813c912484945a36b79146.woff2) format("woff2");
}

@font-face {
  font-family: HelveticaMedium;
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url(/fonts/HelveticaNeueMedium.woff2) format("woff2");
}

@font-face {
  font-family: Staaliches;
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url(/fonts/Staatliches-Regular.woff2) format("woff2");
}

@font-face {
  font-family: Futura;
  font-weight: 400;
  font-style: oblique;
  font-display: fallback;
  src: url(/fonts/futura_o4.woff2) format("woff2");
}

:root {
  --swiper-theme-color: #515151 !important;
  --swiper-pagination-bullet-size: 10px !important;
  --swiper-pagination-bullet-horizontal-gap: 4px !important;
}

a {
  text-decoration: none !important;
}

.error-404-title {
  font-size: 30px;
  margin-bottom: 0px;
  font-family: Staaliches;
  font-weight: 600;
  font-style: normal;
  color: #2a5993;
  text-transform: uppercase;
}

.error-title {
  font-family: HelveticaRegular;
  font-size: 16px;
  color: #2a5993;
  margin: 10px 0px;
}

.back-to-home {
  border: 1px solid transparent;
  color: #2a5993;
  font-size: 16x;
  text-transform: capitalize;
  text-align: center;
  font-family: HelveticaRegular;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 13px;
  border-bottom: 1px solid;
    border-bottom-color: #2a599326;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.fullwidth .swiper-slide:first-child {
  margin-left: 1rem;
}

.fullwidth .swiper-slide .slider-link {
  margin-right: 1rem;
}

.fullwidth-custom .swiper-slide:first-child {
  margin-left: 0.4rem;
}

.fullwidth-custom .swiper-slide .slider-link {
  margin-right: 0.6rem;
}

.slider-ratio-box {
  position: relative;
  padding-bottom: 100%;
}

.aspect-ratio-3-2  {
  position: relative;
  padding-bottom: 60%;
}

.ellipsis-text {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4em;
}

.galery-1  {
  position: relative;
  padding-bottom: 74%;
  @media only screen and (max-width: 600px) {
    padding-bottom: 72%;
  }
}

.galery-2 {
  position: relative;
  padding-bottom: 150%;
}

.video-slider .swiper-button-prev {
  border-radius: 50%;
  min-width: 0;
  line-height: 1;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  height: 3rem;
  width: 3rem;
}

.video-slider .swiper-button-prev::after {
  font-size: medium !important;
  color: white;
}

.video-slider .swiper-button-next {
  border-radius: 50%;
  min-width: 0;
  line-height: 1;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  height: 3rem;
  width: 3rem;
}

.video-slider .swiper-button-next::after {
  font-size: medium !important;
  color: white;
}
